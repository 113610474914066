import yalando from "images/companies/yalando.jpeg";
import heykarl from "images/companies/heykarl.jpeg";
import spotifiz from "images/companies/Spotifiz.jpeg";

const findJobsData = {
  jobs: [
    {
      imageSrc: yalando,
      title: "Yalando",
      rating: "4.8",
      de: {
        description: "Das ist auf deutsch.",
        locationText: "Rome / Remote",
        pricingText: "EUR 60-100/hour",
      },
      en: {
        description: "This is in english.",
        locationText: "Rome / Remote",
        pricingText: "EUR 60-100/hour",
      },
    },
    {
      imageSrc: spotifiz,
      title: "Spotifiz",
      rating: 4.9,
      de: {
        description: "Das ist auf deutsch.",
        locationText: "Stockholm / Remote",
        pricingText: "EUR 60-100/hour",
      },
      en: {
        description: "This is in english.",
        locationText: "Stockholm / Remote",
        pricingText: "EUR 60-100/hour",
      },
    },
    {
      imageSrc: heykarl,
      title: "hey.karl",
      rating: "4.0",
      de: {
        description: "Das ist auf deutsch.",
        locationText: "Berlin / Remote",
        pricingText: "EUR 70-95/hour",
      },
      en: {
        description: "This is in english.",
        locationText: "Berlin / Remote",
        pricingText: "EUR 70-95/hour",
      },
    }
  ]
};

export default findJobsData;