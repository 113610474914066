import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/menu.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/ThreeColSimple.js";
import HighlightedFields from "components/features/ThreeColWithSideImage"
//import StatsFeature from "components/features/TwoColSingleFeatureWithStats2.js";

import { useTranslation } from "react-i18next";

import SupportIconImage from "../images/support-icon.svg";
import ShieldIconImage from "../images/shield-icon.svg";
import CustomizeIconImage from "../images/customize-icon.svg";
import FastIconImage from "../images/fast-icon.svg";
import ReliableIconImage from "../images/reliable-icon.svg";
import SimpleIconImage from "../images/simple-icon.svg";

//import CustomerLoveIconImage from "images/simple-icon.svg";
import CustomSoftwareTeaser1 from "images/customsoftware/shaking-hands-one.jpeg"

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export const SoftwareDevelopmentService = () => {
  const { t } = useTranslation();

  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>{t('textTitleSoftwareDevelopmentSubheading')}</Subheading>}
        heading={t('textTitleSoftwareDevelopment')}
        description={t('textMainTextSoftwareDevelopment')}
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imagesrc={CustomSoftwareTeaser1}
      />
      <HighlightedFields
        heading={t('textTitleSoftwareDevelopmentStrength')}
        subheading=""
        description={t('textTextSoftwareDevelopmentStrength')}
        cards={[
          {
            imageSrc: ShieldIconImage,
            title: "ML & AI",
            description: t('textTextSoftwareDevelopmentML')
          },
          { imageSrc: SupportIconImage, title: "Cloud", description: t('textTextSoftwareDevelopmentCloud')
        },
          { imageSrc: CustomizeIconImage, title: "Web-Applications", description: t('textTextSoftwareDevelopmentWebApp') },
          { imageSrc: ReliableIconImage, title: "Android & iOS Apps", description: t('textTextSoftwareDevelopmentApps') },
          { imageSrc: FastIconImage, title: "Fast", description: t('textTextSoftwareDevelopmentFast')  },
          { imageSrc: SimpleIconImage, title: "Easy", description: t('textTextSoftwareDevelopmentEasy')  }
        ]}
      >
      </HighlightedFields>
      {/* <StatsFeature
        heading={<>We have been doing this <wbr /> since <span tw="text-primary-500">2022.</span></>}
      >
      </StatsFeature> */}
      <Features
        subheading={<Subheading>{t('textSoftwareDevelopmentSota')}</Subheading>}
        heading="Moderne Technologien und AI"
        description={t('textSoftwareDevelopmentModern')}
        showCards={false}
        linkText=""
      />
      <Footer />
    </AnimationRevealPage>
  );
};
export default SoftwareDevelopmentService;
