import "styles/globalStyles.css";
import React from "react";
import "firebase.js";

import ComponentRenderer from "ComponentRenderer.js";
import ThankYouPage from "ThankYouPage.js";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import AboutUs from "pages/AboutUs";
import Home from "pages/Home";
import Pricing from "pages/Pricing.js";
import Questions from "pages/Questions";
//import { firebaseApp } from "firebase.js";
import { getAuth } from "firebase/auth";
import Freelancer from "pages/Freelancer";
import SoftwareDevelopmentService from "pages/SoftwareDevelopmentService";
import CreateProfile from "pages/CreateProfile";
import FindJob from "pages/FindJob";
import EditBasicinfos from "pages/Profile/EditBasicinfos";

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;
  // Initialize Firebase
  //const app = firebaseApp
  const appAuth = getAuth()
  //const analytics = getAnalytics(app);
  //console.log("FirebseAppName:", app.name);

  appAuth.onAuthStateChanged((user) => {
    if (user) {
      console.log("This user is logged in!")
    }
  });

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/components/:type/:subtype/:name" element={<ComponentRenderer />}>
        </Route>
        <Route path="/components/:type/:name" element={<ComponentRenderer />}>
        </Route>
        <Route path="/thank-you" element={<ThankYouPage />}>
        </Route>
        <Route path="/about" element={<AboutUs />}>
        </Route>
        <Route path="/faqs" element={<Questions />}>
        </Route>
        <Route path="/pricing" element={<Pricing />}>
        </Route>
        <Route path="/join" element={<CreateProfile />}>
        </Route>
        <Route path="/profile/basic" element={<EditBasicinfos />}>
        </Route>
        <Route path="/jobs" element={<FindJob />}> </Route>
        <Route path="/software-develpoment" element={<SoftwareDevelopmentService />}>
        </Route>
        <Route path="/freelancer" element={<Freelancer />}>
        </Route>
        <Route path="/" element={<Home />}>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
