import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/menu.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import QuestionsBlock from "components/faqs/TwoColumnPrimaryBackground"
// import { useTranslation } from "react-i18next";


export const SoftwareDevelopmentService = () => {
  // const { t } = useTranslation();

  return (
    <AnimationRevealPage>
      <Header />
      <QuestionsBlock
        faqs={[
          {
            question: "How do you vet freelancers before adding them to your platform? ",
            answer:
              "We have a rigorous vetting process to ensure only the most skilled and reliable freelancers are available to our clients. This process includes an evaluation of their professional background, portfolio review, and a series of interviews and tests to assess their skills, professionalism, and communication abilities. We also continuously monitor freelancer performance to maintain high-quality standards on our platform."
          },
          {
            question: "Is there a fee if a freelancer decides to transition to a permanent position within the company?",
            answer:
              "If a freelancer chooses to become a permanent employee after 18 months of collaboration, there will be no fee involved. For transitions occurring before this period, any applicable fees will be determined based on the specific terms outlined in your contract."
          },
        ]}
      >

      </QuestionsBlock>
      <Footer />
    </AnimationRevealPage>
  );
};
export default SoftwareDevelopmentService;
