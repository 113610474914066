import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import Header from "components/headers/menu.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/ThreeColSimple.js";
import HighlightedRoles from "components/highlightedRoles/SingleCol.js"
import StatsFeature from "components/features/TwoColSingleFeatureWithStats2.js";

import { useTranslation } from "react-i18next";

import MyDefaultImage from "images/simple-icon.svg"
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import statsImage from "images/main/office-building-sweden.jpg";
import officeImage from "images/main/office-berlin-2022-small.jpg";


const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;
const HeroContainer = tw.div`z-1 relative mt-12 px-6 sm:px-8 mx-auto h-full flex flex-col`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-primary-500 leading-snug sm:mt-0`} // -mt-24
  span {
    ${tw`inline-block mt-2`}
  }
`;

// const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Home = () => {
  const { t } = useTranslation();


  return (
    <AnimationRevealPage>
      <Header />
      <HeroContainer>
        <Content>
          <Heading>
            Find Amazing People
            <br />
            For Your IT Needs!
          </Heading>
          {/* <PrimaryAction>Get in touch</PrimaryAction> */}
        </Content>
      </HeroContainer>
      <MainFeature1
        subheading={<Subheading>{t('textTitleAboutSandbay')}</Subheading>}
        heading={t('textTeaserAboutSandbay')}
        description={t('textMainAboutSandbay')}
        buttonRounded={false}
        primaryButtonText="Get in touch"
        primaryButtonUrl="/join"
        imagesrc={officeImage}
      />
      <HighlightedRoles
        heading={t('textTitleOurFocus')}
        description={t('textTeaserOurFocus')}
      >
      </HighlightedRoles>
      <StatsFeature
        // heading={<Trans i18nKey={'textTitleTrackRecord'}  components={[<h2/>,   <wbr />,<span/>]}/>}
        imagesrc={statsImage}
        heading={t('textTitleTrackRecord')}
        description={t('textDescTrackRecord')}
        primaryButtonUrl="/freelancer"
        statistics={[
          {
            key: "Clients",
            value: "9+"
          },
          {
            key: "Projects",
            value: "28+"
          },
          {
            key: "Placements",
            value: "48+"
          }
        ]}
      >
      </StatsFeature>
      <Features
        subheading={<Subheading>Values</Subheading>}
        heading={t('textMainVales')}
        description={t('textMainAboutSandbay')}
        cards={[
          {
            imageSrc: MyDefaultImage,
            title: "24/7 Support",
            description: t('textMainValues24hSupport')
          },
          {
            imageSrc: ShieldIconImage,
            title: "Fair",
            description: t('textMainValuesStrongTeams')
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: t('textMainValuesCustomer')
          },
        ]}
        linkText=""
      />
      <Footer />
    </AnimationRevealPage>
  );
};
export default Home;