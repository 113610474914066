import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/menu.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
import HighlightedRoles from "components/highlightedRoles/SingleCol.js"
import StatsFeature from "components/features/TwoColSingleFeatureWithStats2.js";

// import Features from "components/features/ThreeColWithSideImage.js";
import { useTranslation } from "react-i18next";

import MyDefaultImage from "images/simple-icon.svg"
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Freelancer = () => {
  const { t } = useTranslation();

  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>{t('textFreelanceTitleAboutSandbay')}</Subheading>}
        heading={t('textFreelanceTeaserAboutSandbay')}
        description={t('textFreelanceMainAboutSandbay')}
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imagesrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <HighlightedRoles
        heading={t('textFreelanceTitleOurFocus')}
        description={t('textFreelanceTeaserOurFocus')}
      >
      </HighlightedRoles>
      <StatsFeature
        // heading={<Trans i18nKey={'textTitleTrackRecord'}  components={[<h2/>,   <wbr />,<span/>]}/>}
        heading={t('textFreelanceTitleTrackRecord')}
        description={t('textFreelanceDescTrackRecord')}
        primaryButtonUrl="/freelancer"
        statistics={[
          {
            key: "Clients",
            value: "9+"
          },
          {
            key: "Projects",
            value: "28+"
          },
          {
            key: "Placements",
            value: "48+"
          }
        ]}
      >
      </StatsFeature>
      <Features
        subheading={<Subheading>Values</Subheading>}
        heading={t('textFreelanceMainVales')}
        description={t('textFreelanceMainAboutSandbay')}
        cards={[
          {
            imageSrc: MyDefaultImage,
            title: "24/7 Support",
            description: t('textFreelanceMainValues24hSupport')
          },
          {
            imageSrc: ShieldIconImage,
            title: "Fair",
            description: t('textFreelanceMainValuesStrongTeams')
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: t('textFreelanceMainValuesCustomer')
          },
        ]}
        linkText=""
      />
      <Footer />
    </AnimationRevealPage>
  );
};
export default Freelancer;