import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next';
import XHR from "i18next-http-backend" 

i18n
  .use(XHR)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: true,
    fallbackLng: 'de',
    detection: {
      order: ['localStorage', 'querystring', 'navigator'],
      lookupQuerystring: 'lng',
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // language resources
    resources: {
      de: {
        translation: {
          // General
          labelNext: "Weiter",
          // Menu 
          menuFindFreelancer: "Freelancer finden",
          menuFindSofwareDevelopment: "Individuelle Software",
          menuFindJobs: "Jobs finden",
          menuPrivateEquity: "Private Equity",
          // Main
          textTitleAboutSandbay: "Über Sandbay",
          textTeaserAboutSandbay: "Warum Sandbay?",
          textTitleOurFocus: "Unsere Leistungen",
          textTeaserOurFocus: "Was auch immer Ihr Unternehmen sucht, Sandbay hat das richtige Talent für Sie.",
          textMainAboutSandbay: "Bringen Sie Ihre IT-Projekte mit den passenden Partnern auf Erfolgskurs. Wir vermitteln Ihnen genau die Unterstützung, die Sie brauchen - sei es für einzelne Projektabschnitte, durch erfahrene Freelancer oder mit einem maßgeschneiderten Team. Vertrauen Sie auf unsere Kompetenz als Ihr verlässlicher Ansprechpartner.",
          starkesPreScreening: "starkes pre-screening",
          textTitleTrackRecord: 'Wir machen das schon <wbr />seit <span tw="text-primary-500">2022.</span>',
          textDescTrackRecord: "Seit 2018 hat sich unsere vielfältige Gruppe von Freiberuflern zu einer starken Gemeinschaft zusammengefunden. Im Jahr 2022 haben wir diese Plattform eingeführt, um unser Engagement für Spitzenleistungen zu unterstreichen. Wir prüfen neue Freiberufler sorgfältig und stellen sicher, dass unsere Gemeinschaft hohe Standards einhält. Durch verschiedene Projekte haben wir wertvolle Erfahrungen gesammelt und eine Kultur der ständigen Verbesserung gefördert. Gestalten Sie mit uns die Zukunft in Ihren Projekten.",          // Create Profile
          textMainValues24hSupport: "Unser 24/7 Support steht Ihnen rund um die Uhr zur Verfügung. Wir sind immer für Sie da!",
          textMainValuesStrongTeams: "Fairness ist unser Prinzip. Transparente Konditionen für Vertrauen und Zufriedenheit.",
          textMainValuesCustomer: "Wir setzen alles daran, Ihre Erwartungen zu übertreffen.",
          textMainVales: "Darum sollten Sie mit uns arbeiten.",
          // Freelance
          textFreelanceTitleAboutSandbay: "Der passende Freelancer",
          textFreelanceTeaserAboutSandbay: "Warum Sandbay?",
          textFreelanceTitleOurFocus: "Unser Fokus",
          textFreelanceTeaserOurFocus: "Was auch immer Ihr Unternehmen sucht, Sandbay hat das richtige Talent für Sie.",
          textFreelanceMainAboutSandbay: "Wir finden das Talent oder Unternehmen, das Sie brauchen. Wir liefern nur qualifizierte Kandidaten. Keine Kosten bis zur Einstellung. Volle Unterstützung von unseren Profis.",
          textFreelanceStrongPreScreening: "starkes pre-screening",
          textFreelanceTitleTrackRecord: 'Wir machen das schon <wbr />seit <span tw="text-primary-500">2022.</span>',
          textFreelanceDescTrackRecord: "Seit 2018 hat sich unsere vielfältige Gruppe von Freiberuflern zu einer starken Gemeinschaft zusammengefunden. Im Jahr 2022 haben wir diese Plattform eingeführt, um unser Engagement für Spitzenleistungen zu unterstreichen. Wir prüfen neue Freiberufler sorgfältig und stellen sicher, dass unsere Gemeinschaft hohe Standards einhält. Durch verschiedene Projekte haben wir wertvolle Erfahrungen gesammelt und eine Kultur der ständigen Verbesserung gefördert. Gestalten Sie mit uns die Zukunft in Ihren Projekten.",          // Create Profile
          textFreelanceMainValues24hSupport: "Unser 24/7 Support steht Ihnen rund um die Uhr zur Verfügung. Wir sind immer für Sie da!",
          textFreelanceMainValuesStrongTeams: "Fairness ist unser Prinzip. Transparente Konditionen für Vertrauen und Zufriedenheit.",
          textFreelanceMainValuesCustomer: "Wir setzen alles daran, Ihre Erwartungen zu übertreffen.",
          textFreelanceMainVales: "Darum sollten Sie mit uns arbeiten.",
          // Software Development
          textTitleSoftwareDevelopment: "Individuelle Software­entwicklung",
          textTitleSoftwareDevelopmentSubheading: "Schnell und auf dem neuesten Stand der Technik",
          textMainTextSoftwareDevelopment: "Maßgeschneiderte Lösungen, die exakt auf Ihre Bedürfnisse zugeschnitten sind",
          textTitleSoftwareDevelopmentStrength: "Unsere Stärken",
          textTextSoftwareDevelopmentStrength: "Damit können Sie rechnen",
          textTextSoftwareDevelopmentML: "Intelligente Lösungen durch maßgeschneiderte Machine Learning und AI-Integration.",
          textTextSoftwareDevelopmentCloud: "Zuverlässige, skalierbare Cloud-Services für optimierte Lösungen.",
          textTextSoftwareDevelopmentWebApp: "Benutzerfreundliche, responsive Webanwendungen für alle Endgeräte.",
          textTextSoftwareDevelopmentApps: "Innovative mobile Apps, die auf Android und iOS begeistern.",
          textTextSoftwareDevelopmentFast: "Schnelle Entwicklung und Markteinführung ohne Qualitätseinbußen.",
          textTextSoftwareDevelopmentEasy: "Einfache Kommunikation und schnelle Hilfe",
          textSoftwareDevelopmentModern: "Unser Unternehmen steht für Innovation und Fortschritt in der Softwareentwicklung. Wir nutzen einen modernen Software Stack und sind versiert in führenden Technologien wie Java/Kotlin, NextJS, TypeScript, ReactJs, Python, Postgres, Firebase, Keycloak, Docker und vielen mehr. Diese Vielfalt ermöglicht es uns, flexible und leistungsstarke Lösungen zu entwickeln, die genau auf die Anforderungen unserer Kunden zugeschnitten sind. Durch die Integration von künstlicher Intelligenz in unsere Projekte heben wir die Effizienz und Intelligenz der entwickelten Software auf ein neues Level, um Ihnen einen entscheidenden Wettbewerbsvorteil zu verschaffen.",
          textSoftwareDevelopmentSota: "Auf dem neuesten Stand",
          // Create Profil
          titleJoinAsFreelancer: "Als Freelancer Anmelden",
          titleJobDesc: "Was machst du beruflich?",
          exampleJobDesc: "z.B. Full-Stack Developer oder Designer",
          labelFirstName: "Vorname",
          exampleFirstName: "z.B. Max",
          labelEmail: "E-Mail Adresse",
          exampleEmail: "z.B. max.mustermann@example.org",
          labelLastName: "Nachname",
          exampleLastName: "z.B. Mustermann",
          labelPassword: "Passwort",
          titleFreelancer: "Als Freiberufler",
          titleCompany: "Als Unternehmen",
          errorMessagePassword: "Dein Passwort ist zu schwach.",
          errorMessageMail: "Die angegebene eMail-Adresse ist nicht korrekt.",
          errorMessageGeneric: "Es ist ein Fehler aufgetreten",
          // Edit Basic Info
          titleSkills: "Fähigkeiten und Technologien",
          labelCity: "Stadt",
          labelDayrate: "Tagessatz in Euro",
          exampleSkills: "z.B. Java, Dev-Ops ",
          exampleCity: "z.B. Berlin, Paris, Rom",
          exampleDayrate: "z.B. 520"
        }
      },
      en: {
        translation: {
          // General
          labelNext: "Next",
          // Menu 
          menuFindFreelancer: "Find Freelancer",
          menuFindSofwareDevelopment: "Custom Software",
          menuFindJobs: "Find Jobs",
          menuPrivateEquity: "Private Equity",
          // Main
          textTitleAboutSandbay: "About Sandbay",
          textTeaserAboutSandbay: "Why Sandbay?",
          textTitleOurFocus: "Our Services",
          textTeaserOurFocus: "Whatever your comapany is looking for, Sandbay has the talent for you.",
          textMainAboutSandbay: "Get your IT projects on the road to success with the right partners. We provide you with exactly the support you need - be it for individual project phases, through experienced freelancers or with a tailor-made team. Place your trust in our expertise as your reliable partner.",
          starkesPreScreening: "strong pre-screening",
          textTitleTrackRecord: 'We have been doing this <wbr /> since <span tw="text-primary-500">2022.</span>',
          textDescTrackRecord: "Working together since 2018, our diverse group of freelancers has come together as a strong community. In 2022, we introduced this platform, highlighting our commitment to excellence. We carefully assess new freelancers, ensuring our community maintains high standards. Through various projects, we've gained valuable expertise, fostering a culture of ongoing improvement. Shape the future of your projects with us.",
          textMainValues24hSupport: "Our 24/7 support is available around the clock. We are always there for you!",
          textMainValuesStrongTeams: "Fairness is our principle. Transparent conditions for trust and satisfaction.",
          textMainValuesCustomer: "We do everything we can to exceed your expectations.",
          textMainVales: "Why you should work with us.",
          // Freelance
          textFreelanceTitleAboutSandbay: "About Sandbay",
          textFreelanceTeaserAboutSandbay: "Why Sandbay?",
          textFreelanceTitleOurFocus: "Our Focus",
          textFreelanceTeaserOurFocus: "Whatever your comapany is looking for, Sandbay has the talent for you.",
          textFreelanceMainAboutSandbay: "We will find the talent or company of your needs. We supply only qualified candidates. No costs until hiring. Full support from our professionals.",
          textFreelanceStrongPreScreening: "strong pre-screening",
          textFreelanceTitleTrackRecord: 'We have been doing this <wbr /> since <span tw="text-primary-500">2022.</span>',
          textFreelanceDescTrackRecord: "Working together since 2018, our diverse group of freelancers has come together as a strong community. In 2022, we introduced this platform, highlighting our commitment to excellence. We carefully assess new freelancers, ensuring our community maintains high standards. Through various projects, we've gained valuable expertise, fostering a culture of ongoing improvement. Shape the future of your projects with us.",
          textFreelanceMainValues24hSupport: "Our 24/7 support is available around the clock. We are always there for you!",
          textFreelanceMainValuesStrongTeams: "Fairness is our principle. Transparent conditions for trust and satisfaction.",
          textFreelanceMainValuesCustomer: "We do everything we can to exceed your expectations.",
          textFreelanceMainVales: "Why you should work with us.",
          // Software Development
          textTitleSoftwareDevelopment: "Customized software development",
          textTitleSoftwareDevelopmentSubheading: "Fast and State of the Art",
          textMainTextSoftwareDevelopment: "Customized solutions that are precisely tailored to your needs",
          textTitleSoftwareDevelopmentStrength: "Our strengths",
          textTextSoftwareDevelopmentStrength: "You can count on it",
          textTextSoftwareDevelopmentML: "Intelligent solutions through customized machine learning and AI integration.",
          textTextSoftwareDevelopmentCloud: "Reliable, scalable cloud services for optimized solutions.",
          textTextSoftwareDevelopmentWebApp: "User-friendly, responsive web applications for all end devices.",
          textTextSoftwareDevelopmentApps: "Innovative mobile apps that inspire on Android and iOS.",
          textTextSoftwareDevelopmentFast: "Fast development and market launch without compromising on quality.",
          textTextSoftwareDevelopmentEasy: "Easy communication and quick help",
          textSoftwareDevelopmentModern: "Our company stands for innovation and progress in software development. We use a modern software stack and are well-versed in leading technologies such as Java/Kotlin, NextJS, TypeScript, ReactJs, Python, Postgres, Firebase, Keycloak, Docker and many more. This diversity enables us to develop flexible and powerful solutions that are precisely tailored to our customers' requirements. By integrating artificial intelligence into our projects, we raise the efficiency and intelligence of the developed software to a new level to give you a decisive competitive advantage.",
          textSoftwareDevelopmentSota: "Up to date.",
          // Create Profile
          titleJoinAsFreelancer: "Sign Up as a Freelancer",
          titleJobDesc: "What's your profession?",
          exampleJobDesc: "e.g. Full-Stack Developer or Designer",
          labelFirstName: "First Name",
          exampleFirstName: "e.g. Joe",
          labelEmail: "Email Address",
          exampleEmail: "e.g. joe.doe@example.org",
          labelLastName: "Last Name",
          exampleLastName: "e.g. Doe",
          labelPassword: "Password",
          titleFreelancer: "As Freelancer",
          titleCompany: "As Company",
          errorMessagePassword: "Your Password is too weak.",
          errorMessageMail: "The Mail address you entered looks not right.",
          errorMessageGeneric: "An Error occured:",
          // Edit Basic Info
          titleSkills: "Skills and Technologies",
          labelCity: "City",
          labelDayrate: "Daily Rate",
          exampleSkills: "z.B. Java, Dev-Ops ",
          exampleCity: "z.B. Berlin, Paris, Rom",
          exampleDayrate: "z.B. 520"
 
        }
      },
    }
  });

export default i18n;
