import React, { useState } from "react";

import styled from "styled-components"; //eslint-disable-line
import Header from "components/headers/menu.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg"
import { useTranslation } from "react-i18next";
import { getAuth } from "firebase/auth";
import skillsDBmocked from "content/skillslist";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const SkillInputContainer = tw.div`relative pt-5 mt-6 border-b-2`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const SkillInput = tw.input`border-none bg-transparent outline-none`;

const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;
const SkillTag = tw.span`inline-block rounded pr-1 pl-2 mr-1 mb-1 bg-gray-100 text-primary-500`
const SkilltagRemover = tw.a`cursor-pointer inline-block mx-2 mb-0`
const ListElement = tw.li`px-3 py-2 cursor-pointer hover:bg-gray-200 hover:text-primary-500`
const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`


const EditBasicinfos = () => {
  const { t } = useTranslation();
  const [skills, setSkills] = useState([]);
  const [city, setCity] = useState('');
  const [dayrate, setDayrate] = useState(100);
  const [loading] = useState(false);
  const [filteredSkills, setFilteredSkills] = useState([])
  const [currentSkillInput, setCurrentSkillInput] = useState('')


  const appAuth = getAuth()

  const handle = (e) => {
    e.preventDefault()
    console.log(skills)
    console.log(city)
    console.log(dayrate)
    console.log(appAuth.currentUser.email)
  }


  const onChangeSkillInput = (userInput) => {
    setCurrentSkillInput(userInput)
    setCurrentSkill(userInput)
  }

  const setCurrentSkill = (userInput) => {
    const allMatchedSkills = skillsDBmocked.filter(skill =>
      new RegExp(userInput, 'i').test(skill));
    const sortedList = allMatchedSkills.filter(string =>
      string.toLocaleLowerCase().startsWith(userInput.toLocaleLowerCase())).slice(0, 5).concat(allMatchedSkills.filter((string =>
        !string.toLocaleLowerCase().startsWith(userInput.toLocaleLowerCase()))).slice(0, 5)
      )
      ;
    setFilteredSkills(sortedList)
  }

  const addSkill = (skill => {
    setSkills(skills.concat(skill));
    console.log("Skill added", skill)
    setCurrentSkillInput('')
    setFilteredSkills([])
  }
  )

  const removeSkillTag = (skill => {
    setSkills(skills.filter(string =>
      string !== skill))
    console.log("Skill removed", skill)
    console.log("Skills:", skills.filter(string =>
      string !== skill))
  })


  const renderList = () => {
    return <ul class="py-1">
      {filteredSkills.map(item => {
        return <ListElement key={item} onClick={(e) => { addSkill(item) }}>
          {item}
        </ListElement>
      }
      )}

      {filteredSkills.length === 0 && currentSkillInput > 0 ? (
        <li v-if="!searchResults.length" class="px-3 py-2 text-center">
          No Matching Results
        </li>) : ""}
    </ul>
  }

  return (
    <>
      <Header />
      <Container>
        <Content>
          <FormContainer>
            <div tw="mx-auto max-w-4xl">
              <h2>{t('titleJoinAsFreelancer')}</h2>
              <form action="#">
                <SkillInputContainer>
                  <Label htmlFor="skills-input">{t('titleSkills')}</Label>
                  {skills.map((skill) => {
                    return (<SkillTag>{skill}<SkilltagRemover onClick={() => { removeSkillTag(skill) }}>x</SkilltagRemover></SkillTag>)
                  })}
                  <SkillInput id="skills-input" type="text" value={currentSkillInput} name="skills" placeholder={t('exampleSkills')}
                    onChange={(e) => { onChangeSkillInput(e.target.value) }} />
                </SkillInputContainer>

                {renderList()}
                <TwoColumn>
                  <Column>
                    <InputContainer>
                      <Label htmlFor="city-input">{t('labelCity')}</Label>
                      <Input id="city-input" type="text" name="city" placeholder={t('exampleCity')}
                        onChange={(e) => { setCity(e.target.value) }} />

                    </InputContainer>
                  </Column>
                  <Column>
                    <InputContainer>
                      <Label htmlFor="dayrate-input">{t('labelDayrate')}</Label>
                      <Input id="dayrate-input" type="text" name="dayrate" placeholder={t('exampleDayrate')}
                        onChange={(e) => { setDayrate(e.target.value) }} />

                    </InputContainer>
                  </Column>
                </TwoColumn>
                <SubmitButton type="submit" value="Submit" onClick={handle}>{!loading ? t('labelNext') : "Loading..."}</SubmitButton>
              </form>
            </div>
            <SvgDotPattern1 />
          </FormContainer>
        </Content>
      </Container>
      <Footer />
    </>
  );
};

export default EditBasicinfos;
