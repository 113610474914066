import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/menu.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import CallToActionArea from "components/features/TwoColWithTwoFeaturesAndButtons.js"
import ProjectCards from "components/cards/ThreeColSlider.js";
import serverIllustrationImageSrc from "images/server-illustration-2.svg";
import i18nJobs from 'content/findjobs.js'
import i18n from "i18n";


// const Subheading = tw.span`uppercase tracking-wider text-sm`;
const FindJob = () => {
//  const { t } = useTranslation();

  const cards = i18nJobs.jobs.map( elem => {
    return {
      imageSrc: elem.imageSrc,
      title: elem.title,
      description: elem[i18n.language].description,
      locationText: "Rome / Remote",
      pricingText: "EUR 60-100/hour",
      rating: "4.8",
    }
  });

  return (
    <AnimationRevealPage>
      <Header />
      <CallToActionArea
        imageSrc={serverIllustrationImageSrc}
        heading="Find Jobs and Gigs easily"
        subheading={null}
        features={[]}
        description="Bereit für den nächsten Karriereschritt oder dem nächsten Freelance Gig? Wir platzieren dich strategisch in lukrativen und interessanten Jobs und Projekten, die perfekt zu Deinen Fähigkeiten passen. Vereinfache Deine Jobsuche und starte Deine Erfolgsreise noch heute!"
        primaryButtonText="Sign Up for Perm. Positions"
        secondaryButtonText="Sign Up for freelance Gigs"
      >

      </CallToActionArea>
      <ProjectCards
        heading="Sample Projects"
        cards={cards}
      />

      <Footer />
    </AnimationRevealPage>
  );
};

export default FindJob;