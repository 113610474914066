import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

//const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;
const RolesContainer = tw.div`w-full flex-1 lg:flex justify-between items-start max-w-screen-lg mx-auto`;

const Column = tw.div`flex flex-col items-center`;

const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl	relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-sm lg:text-base font-medium`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

const SingleCol = ({
  subheading = "Our Focus",
  heading = "Our Focus",
  description = "Description Text",
  highlightedRoleTitles = [
    {
      title: "Junior/Sensior Backend Engineer Java/Kotlin",
      star: false
    },
    {
      title: "Junior/Sensior Frontend Engineer React",
      star: false
    },
    {
      title: "Junior/Sensior Backend Engineer GoLang",
      star: false
    },
    {
      title: "System Reliability Engineer",
      star: false
    },
    {
      title: "Senior Project Manager",
      star: false
    },
    {
      title: "Engineering Manager",
      star: false
    },
    {
      title: "Performance Marketing Expert (Google Ads)",
      star: false
    },
    {
      title: "Social Media Marketing (Facebook/Instagram)",
      star: false
    },
    {
      title: "Recruiter (Freelance only)",
      star: false
    },
  ],
  highlightedRolesSplit = highlightedRoleTitles.length/2
}) => {

  return (
    <Container>
        <Column>
          <HeaderContent>
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
          </HeaderContent>
          <RolesContainer>

          <FAQSContainer>
            {highlightedRoleTitles.slice(0, highlightedRolesSplit).map((faq, index) => (
              <FAQ
                key={index}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.title}</QuestionText>
                </Question>
              </FAQ>
            ))}
          </FAQSContainer>
          <FAQSContainer>
            {highlightedRoleTitles.slice(highlightedRolesSplit).map((faq, index) => (
              <FAQ
                key={index}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.title}</QuestionText>
                </Question>
              </FAQ>
            ))}
          </FAQSContainer>
          </RolesContainer>
        </Column>
      <DecoratorBlob1/>
      <DecoratorBlob2 />
    </Container>
  );
};
export default SingleCol;
